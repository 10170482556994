const initState = {
    authError: null
}

const authReducer = (state = initState, action: any) => {
    // check action type with string
    switch (action.type) {
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError: `Login Failed: ${action.err.message}`
            }
        case 'LOGIN_SUCCESS':
            console.log('success!')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_SUCCESS':
            console.log(state)
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.err.message
            }
        default:
            return state
    }
}

export default authReducer