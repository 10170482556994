import React, { useState, useEffect } from 'react'
import "../styles/styles.css"
import Logo from "../images/logo.png"
import homepic1 from "../images/Site1.png"
import homepic2 from "../images/Site2.png"
import homepic3 from "../images/Site3.png"
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { LogOut } from '../store/actions/usersActions';
import { compose } from 'redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';

interface propsInterface {
    signout: Function
    auth: any
    content: any
    profile: any
    importantLinks: [{
        id: string,
        url: string,
        name: string
    }]
    PDFs: [{
        name: string,
        id: string,
        url: string
    }]
}
function Home(props: propsInterface) {
    // STATE
    const [contentData, setContentData] = useState({
        about: "",
        subjects: ""
    })

    // PROPS
    const { auth, signout, importantLinks, PDFs, content, profile } = props

    // get content
    useEffect(() => {
        if (isLoaded(content) && !isEmpty(content)) {
            const { content: Content } = content
            const { GpBaxFgrNVRIR2tYuWcw: data } = Content
            setContentData(data)
        }
    }, [content, props])

    if (!auth.uid) {
        return <Redirect to="/" />
    }
    return (
        <div>
            <nav>
                <div className="nav-wrapper logo">
                    <a href="/home" className="brand-logo "><img className="logo" src={Logo} alt="Home Logo" /></a>
                    <h1 className="center vcenter">Explore Ton Histoire</h1>
                </div>
            </nav>

            <div className="content container">
                <div className="row">
                    <div className="col m12 l8">
                        <div className="card ">
                            <div className="card-content">
                                <span className="card-title"><h2>About this Site</h2></span>
                                <p>{contentData.about}</p></div>
                            <div className="home-images">
                                <img src={homepic1} alt="FirstShowcase" />
                                <img src={homepic2} alt="SecondShowcase" />
                                <img src={homepic3} alt="ThirdShowcase" />
                            </div>
                            <div className="card-content">
                                <span className="card-title"><h2>Subjects</h2></span>
                                <p>{contentData.subjects}</p></div>
                        </div>
                    </div>
                    <div className="col m12 l4">
                        <div className="card">
                            <div className="card-content">
                                <span className="card-title"><h2>Important Links</h2></span>
                                <ul>
                                    {importantLinks && importantLinks.map((link) => {
                                        return <li key={link.id}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a></li>
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-content">
                                <span className="card-title"><h2>Important PDFs</h2></span>
                                <ul>
                                    {PDFs && PDFs.map((PDF) => {
                                        return <li key={PDF.id}><a href={PDF.url} target="_blank" rel="noopener noreferrer">{PDF.name}</a></li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <footer className="page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col l6 s12">
                            <h5 className="white-text">Explore ton Histoire</h5>
                            <p className="grey-text text-lighten-4">This site is a private site not associated with any organization and is password
                            protected. Family and friends who seek access and agree to its conditions may
                request a password from me at <b>vic.barnett@daytonaero.com.</b></p>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        <a target="_blank" href="https://matterhornstudiosllc.com/" rel="noopener noreferrer">©2020 Matterhorn Studios</a>
                        {profile.role === "admin" ? <a href="/admin" className="admin-button waves-effect waves-light btn right green margin-4">Admin Page</a> : null}
                        <button className="waves-effect waves-light btn right green" onClick={(e) => {
                            signout()
                        }}>Logout</button>
                    </div>
                </div>
            </footer>
        </div>
    )
}
// get items from state
const mapStateToProps = (state: any) => {
    return {
        auth: state.firebase.auth,
        content: state.firestore.data,
        importantLinks: state.firestore.ordered.importantLinks,
        PDFs: state.firestore.ordered.PDFs,
        profile: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        signout: () => dispatch(LogOut())
    }
}

export default compose<any>(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: "content",
            doc: "GpBaxFgrNVRIR2tYuWcw",
        },
        {
            collection: "importantLinks",
            orderBy: ["name", "asc"]
        },
        {
            collection: "PDFs",
            orderBy: ["name", "asc"]
        }
    ])
)(Home)