import React, { FormEvent, useState } from 'react'
import { Login } from '../store/actions/usersActions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Logo from '../images/logo.png'
interface propsInterface {
  Login: Function
  auth: any
  users: { authError?: string }
}
function Index(props: propsInterface) {
  const { Login, auth, users } = props
  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  })
  const signIn = (e: FormEvent) => {
    e.preventDefault()
    if (credentials?.username && credentials.password)
      Login(credentials.password, credentials.username)
  }
  if (auth.uid) {
    return <Redirect to="/home" />
  }
  return (
    <div>
      <div className="login">
        <img src={Logo} alt="Logo" className="login-img" />
        <h6 className="center yellow-text darken-6">{users.authError}</h6>
        <input type="text" onChange={(e) => {
          setCredentials({ ...credentials, username: e.target.value })
        }} placeholder="Username" />
        <input type="password" onChange={(e) => {
          setCredentials({ ...credentials, password: e.target.value })
        }} placeholder="Password" />
        <button onClick={signIn} className="waves-effect waves-light btn green">Submit</button>
      </div>
      <footer className="index-footer page-footer">
        <div className="container">
          <div className="row">
            <div className="col l6 s12">
              <h5 className="white-text">Explore ton Histoire</h5>
              <p className="grey-text text-lighten-4">This site is a private site not associated with any organization and is password
              protected. Family and friends who seek access and agree to its conditions may
      request a password from me at <b>vic.barnett@daytonaero.com.</b></p>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <a target="_blank" rel="noopener noreferrer" href="https://matterhornstudiosllc.com/">©2020 Matterhorn Studios</a>
          </div>
        </div>
      </footer>
    </div>

  )
}

// get items from state
const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    users: state.users
  };
};

// get dispatch
const mapDispatchToProps = (dispatch: any) => {
  return {
    Login: (password: string, username: string) => dispatch(Login(password, username)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index)