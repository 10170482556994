import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { changeAbout, changeSubjects, addImportantLinks, removeImportantLinks, addPDF, removePDF } from '../../store/actions/contentActions';
import { storage } from '../../config/fbConfig';

interface adminProps {
    auth: any,
    profile: {
        role: string,
        isLoaded: boolean
    },
    content: any,
    contentRed: {
        contentStatus: string
    },
    changeAbout: Function
    changeSubjects: Function
    addImportantLinks: Function
    removeImportantLinks: Function
    addPDF: Function
    removePDF: Function
    importantLinks: [{
        id: string,
        url: string,
        name: string
    }]
    PDFs: [{
        name: string,
        id: string,
        url: string
    }]
}

function Admin(props: adminProps) {
    // STATE
    const [contentData, setContentData] = useState({
        about: "",
        subjects: ""
    })
    const [linkToAdd, setLinkToAdd] = useState({
        url: "",
        name: ""
    })
    const [PDF, setPDF] = useState<File>()
    const [PDFInfo, setPDFInfo] = useState({
        name: "",
        url: ""
    })
    const [progress, setProgress] = useState(0)
    const [hasPDF, setHasPDF] = useState(false)

    // MATERIALIZE
    useEffect(() => {
        var elem = document.getElementById("textarea1")
        var elem2 = document.getElementById("textarea2")
        if (elem && elem2) {
            window.M.textareaAutoResize(elem);
            window.M.textareaAutoResize(elem2)
        }


        window.M.updateTextFields()
    })

    // PROPS
    const { profile, content, contentRed, changeAbout, changeSubjects, importantLinks, addImportantLinks, removeImportantLinks, addPDF, PDFs, removePDF } = props

    // get content
    useEffect(() => {
        if (isLoaded(content) && !isEmpty(content)) {
            const { content: Content } = content
            const { GpBaxFgrNVRIR2tYuWcw: data } = Content
            setContentData(data)
        }
    }, [content, props])

    // PDF UPLOAD
    const handlePDFUpload = (e: any) => {
        e.preventDefault();
        e.enabled = false;

        //Makes the progress bar visible
        var element: any = document.getElementById("fullProgress");
        element.classList.add("scale-in");

        // get the image from state
        if (PDF) {
            const file: File = PDF;
            const uploadTask = storage.ref(`PDFs/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    alert("PDF uploaded successfully!")
                    setHasPDF(true)
                    storage
                        .ref("PDFs")
                        .child(file.name)
                        .getDownloadURL()
                        .then((url) => {
                            setPDFInfo({ ...PDFInfo, url });
                        });
                }
            );
        }
    };


    // make sure user is an admin
    if (profile.role !== "admin" && profile.isLoaded) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <h3 className="center green-text darken-3">Welcome to the Administration Page!</h3>
            <a href="/home" className="backtohome waves-effect waves-light btn center-align green">Back to homepage</a>
            <h6 className="center green-text">{contentRed.contentStatus}</h6>
            <div className="row">
                <div className="col m12 l6">
                    <div className="card center">
                        <div className="card-title center green-text">Update the "About This Site" section</div>
                        <form className="container" onSubmit={(e) => {
                            e.preventDefault();
                            changeAbout(contentData?.about)
                        }}>
                            <div className="row">
                                <div className="input-field col s12">
                                    <textarea id="textarea1" className="materialize-textarea" value={contentData?.about} onChange={(e) => {
                                        setContentData({ ...contentData, about: e.target.value })
                                    }}></textarea>
                                    <label htmlFor="textarea1">Content</label>
                                </div>
                                <button className="waves-effect btn green center" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
                
                

                <div className="col m12 l6"><div className="card center">
                    <div className="card-title center green-text">Add Important Links to the Site</div>
                    <div className="center green-text">Current Links</div>
        
                        {importantLinks && importantLinks.map((link) => {
                            return (<div className="link" key={link.id}>
                                <div className="left truncate">{link.name} - {link.url}</div>
                                    <button className="btn waves-effect red right" onClick={() => {
                                        removeImportantLinks(link.id)
                                    }}>Remove Link</button>
                            </div>)
                        })}
                    <form className="container" onSubmit={(e) => {
                        e.preventDefault()
                        addImportantLinks(linkToAdd)
                    }}>

                        <div className="center green-text">Add a Link</div>

                        <div className="input-field col s12">
                            <input id="linkName" type="text" className="validate" value={linkToAdd.name} onChange={(e) => {
                                setLinkToAdd({ ...linkToAdd, name: e.target.value })
                            }} />
                            <label htmlFor="linkName">Link Name</label>
                        </div>

                        <div className="input-field col s12">
                            <input id="linkURL" type="text" className="validate" value={linkToAdd.url} onChange={(e) => {
                                setLinkToAdd({ ...linkToAdd, url: e.target.value })
                            }} />
                            <label htmlFor="linkURL">Link URL</label>
                        </div>

                        <div className="row">
                            <button className="center waves-effect btn green" type="submit">Add Link</button>
                        </div>
                    </form>
                </div></div>
            </div>

            <div className="row">
                <div className="col m12 l6"><div className="card center">
                    <div className="card-title center green-text">Update the "Subjects" section</div>
                    <form className="container" onSubmit={(e) => {
                        e.preventDefault();
                        changeSubjects(contentData?.subjects)
                    }}>
                        <div className="row">
                            <div className="input-field col s12">
                                <textarea id="textarea2" className="materialize-textarea" value={contentData?.subjects} onChange={(e) => {
                                    setContentData({ ...contentData, subjects: e.target.value })
                                }}></textarea>
                                <label htmlFor="textarea2">Content</label>
                            </div>
                            <button className="waves-effect btn green center" type="submit">Update</button>
                        </div>
                    </form>
                </div></div>

                <div className="col m12 l6"><div className="card center">
                    <div className="card-title center green-text">Add PDFs to the Site</div>

                        {PDFs && PDFs.map((PDF) => {
                            return (
                                <div className="link" key={PDF.id}>
                                <div className="left truncate">{PDF.name} - {PDF.url}</div>
                                    <button className="btn waves-effect red right" onClick={() => {
                                        removePDF(PDF.id)
                                    }}>Remove PDF</button>
                            </div>
                            )
                        })}
                    <form className="container" onSubmit={(e) => {
                        e.preventDefault()
                        addPDF(PDFInfo)
                    }}>
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="pdfName" type="text" className="validate" value={PDFInfo.name} onChange={(e) => {
                                    setPDFInfo({ ...PDFInfo, name: e.target.value })
                                }} />
                                <label htmlFor="pdfName">PDF Name</label>
                            </div>
                        </div>

                        <div className="row">
                            <h6 className="center green-text">PDF Upload</h6>
                            <div className="file-field input-field">
                                <div className="btn green">
                                    <span>File</span>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files![0] != null) {
                                                const file = e.target.files![0];
                                                setPDF(file);
                                            }
                                        }}
                                        accept=".pdf"
                                        required
                                    />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" />
                                </div>
                            </div>

                            <progress
                                max="100"
                                className="progress scale-transition scale-out green"
                                id="fullProgress"
                                value={progress}
                            />

                            <button
                                className="waves-effect waves-light btn green"
                                onClick={handlePDFUpload}
                            >
                                Upload
						    </button>
                        </div>
                        <div className="row">
                            <button className="btn waves-effect green center" disabled={!hasPDF} type="submit">Add PDF</button>
                        </div>
                    </form>
                </div></div>
            </div>
        </div>
    )
}


// get items from state
const mapStateToProps = (state: any) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        content: state.firestore.data,
        importantLinks: state.firestore.ordered.importantLinks,
        PDFs: state.firestore.ordered.PDFs,
        contentRed: state.content
    };
};

// get dispatch
const mapDispatchToProps = (dispatch: any) => {
    return {
        changeAbout: (content: string) => dispatch(changeAbout(content)),
        changeSubjects: (content: string) => dispatch(changeSubjects(content)),
        addImportantLinks: (link: Object) => dispatch(addImportantLinks(link)),
        removeImportantLinks: (link: string) => dispatch(removeImportantLinks(link)),
        addPDF: (PDF: Object) => dispatch(addPDF(PDF)),
        removePDF: (PDF: string) => dispatch(removePDF(PDF))
    };
};
export default compose<any>(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: "content",
            doc: "GpBaxFgrNVRIR2tYuWcw",
        },
        {
            collection: "importantLinks",
            orderBy: ["name", "asc"]
        },
        {
            collection: "PDFs",
            orderBy: ["name", "asc"]
        }
    ])
)(Admin)