export const Login = (password: string, username: string) => {
	return (dispatch: any, getState: any, { getFirebase }: any) => {
		// init firebase
		const firebase = getFirebase();
		let signUsername

		// check what the user send
		if (username === "user" || username === "User") {
			signUsername = "user@explore.com"
		} else if (username === "admin" || username === "Admin") {
			signUsername = "admin@explore.com"
		} else {
			dispatch({ type: "LOGIN_ERROR", err: "invalid username" })
		}

		// sign in (async)
		firebase
			.auth()
			.signInWithEmailAndPassword(
				// credentials
				signUsername,
				password
			)
			.then(() => {
				dispatch({ type: "LOGIN_SUCCESS" });
			})
			.catch((err: any) => {
				dispatch({ type: "LOGIN_ERROR", err });
			});
	};
};

export const LogOut = () => {
	return (dispatch: any, getState: any, { getFirebase }: any) => {
		// init firebase
		const firebase = getFirebase();

		// sign in (async)
		firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch({ type: "LOGOUT_SUCCESS" });
			})
			.catch((err: any) => {
				dispatch({ type: "LOGOUT_ERROR" });
			});
	};
}
