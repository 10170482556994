import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import authReducer from './userReducer'
import contnetReducer from "./contentReducer"

const rootReducer = combineReducers({
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    users: authReducer,
    content: contnetReducer
})

export default rootReducer