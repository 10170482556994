import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Index from "./pages/index"
import Home from "./pages/home"
import Admin from "./pages/admin";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/admin" component={Admin} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
