const initState = {
    contentStatus: null
}

const authReducer = (state = initState, action: any) => {
    // check action type with string
    switch (action.type) {
        case 'UPDATE_ERROR':
            return {
                ...state,
                contentStatus: `Update Failed: ${action.err.message}`
            }
        case 'UPDATE_SUCCESS':
            return {
                ...state,
                contentStatus: "Successfully updated!"
            }
        default:
            return state
    }
}

export default authReducer