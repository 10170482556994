import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBBV5JLIUybHllG-zgwMLP18WdB9Bqy020",
    authDomain: "barnettsitetwo.firebaseapp.com",
    databaseURL: "https://barnettsitetwo.firebaseio.com",
    projectId: "barnettsitetwo",
    storageBucket: "barnettsitetwo.appspot.com",
    messagingSenderId: "844993721278",
    appId: "1:844993721278:web:d0350b731c6759ec49ffef"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
const storage = firebase.storage();
export { storage, firebase as default };
