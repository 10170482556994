export const changeAbout = (content: string) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // first delete the old item
        const firestore = getFirestore()

        firestore.collection('content').doc("GpBaxFgrNVRIR2tYuWcw").update({
            about: content
        }).then(() => {
            dispatch({ type: 'UPDATE_SUCCESS' })

        }).catch((err: Error) => {
            dispatch({ type: 'UPDATE_ERROR', err })
        })
    };
}

export const changeSubjects = (content: string) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // first delete the old item
        const firestore = getFirestore()

        firestore.collection('content').doc("GpBaxFgrNVRIR2tYuWcw").update({
            subjects: content
        }).then(() => {
            dispatch({ type: 'UPDATE_SUCCESS' })

        }).catch((err: Error) => {
            dispatch({ type: 'UPDATE_ERROR', err })
        })
    };
}

export const addImportantLinks = (link: Object) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // get firestore
        const firestore = getFirestore();

        // add to db
        firestore
            .collection("importantLinks")
            .add(link)
            .then(() => {
                dispatch({ type: "UPDATE_SUCCESS" });
            })
            .catch((err: Error) => {
                dispatch({ type: "UPDATE_ERROR", err });
            });
    };
}

export const removeImportantLinks = (link: string) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // get firestore
        const firestore = getFirestore();

        // add to db
        firestore
            .collection("importantLinks")
            .doc(link)
            .delete()
            .then(() => {
                dispatch({ type: "UPDATE_SUCCESS" });
            })
            .catch((err: Error) => {
                dispatch({ type: "UPDATE_ERROR", err });
            });
    };
}

export const addPDF = (PDF: Object) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // get firestore
        const firestore = getFirestore();

        // add to db
        firestore
            .collection("PDFs")
            .add(PDF)
            .then(() => {
                dispatch({ type: "UPDATE_SUCCESS" });
            })
            .catch((err: Error) => {
                dispatch({ type: "UPDATE_ERROR", err });
            });
    };
}

export const removePDF = (PDF: string) => {
    return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
        // get firestore
        const firestore = getFirestore();

        // add to db
        firestore
            .collection("PDFs")
            .doc(PDF)
            .delete()
            .then(() => {
                dispatch({ type: "UPDATE_SUCCESS" });
            })
            .catch((err: Error) => {
                dispatch({ type: "UPDATE_ERROR", err });
            });
    };
}